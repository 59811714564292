import { createSlice } from "@reduxjs/toolkit";

import type { SearchReduxState } from "@app/types/search-types";

import type { PayloadAction } from "@reduxjs/toolkit";

const initial_state: SearchReduxState["recent_searches"] = {
  origin: null,
  destination: null
};

const recentSearches = createSlice({
  name: "search_form/recent_searches",
  initialState: initial_state,
  reducers: {
    setRecentOrigin: (
      state,
      action: PayloadAction<SearchReduxState["recent_searches"]["origin"]>
    ) => {
      state.origin = action.payload;
    },
    setRecentDestination: (
      state,
      action: PayloadAction<SearchReduxState["recent_searches"]["destination"]>
    ) => {
      state.destination = action.payload;
    }
  }
});

export const { setRecentOrigin, setRecentDestination } = recentSearches.actions;
export default recentSearches.reducer;
