import React from "react";

import { CitySuggestionItem } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/autocomplete-option/city-suggestion-item";
import { LocationSuggestionItem } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/autocomplete-option/location-suggestion-item";
import type { NapiFlexLocationOption } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-napi-predictions";
import { useFastClick } from "@app/modules/search/use-fast-click";

interface AutocompleteOptionProps extends React.HTMLAttributes<HTMLDivElement> {
  suggestion: NapiFlexLocationOption;
  is_active: boolean;
  is_recent_search?: boolean;
  handleOptionClick: (option: NapiFlexLocationOption) => void;
  handleOptionFocus: (option: NapiFlexLocationOption) => void;
}

export const AutocompleteOption = ({
  suggestion,
  is_active,
  handleOptionClick,
  handleOptionFocus,
  is_recent_search = false,
  ...props
}: AutocompleteOptionProps) => {
  const ref = useFastClick<HTMLDivElement>(() => handleOptionClick(suggestion));

  const handleFocus = React.useCallback(
    (_event: React.FocusEvent<HTMLDivElement>) => {
      handleOptionFocus(suggestion);
    },
    [suggestion, handleOptionFocus]
  );

  const SuggestionItem = is_recent_search
    ? CitySuggestionItem
    : suggestion.value.template === "city"
      ? CitySuggestionItem
      : LocationSuggestionItem;

  return (
    <SuggestionItem
      isActive={is_active}
      suggestion={suggestion}
      tabIndex={0}
      ref={ref}
      onFocus={handleFocus}
      {...props}
    />
  );
};
