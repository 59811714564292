import type { PartialSuggestion } from "@app/components/search-form/helpers";
import type { NapiCity, NapiLocation } from "@app/types/napi-geo-entity-types";
import type {
  CitySuggestion,
  LocationSuggestion,
  SearchPlace
} from "@app/types/search-types";

export function cityToPlace(
  city: Pick<
    NapiCity,
    | "id"
    | "full_name"
    | "geohash"
    | "timezone"
    | "place_type"
    | "geo_entity_id"
    | "legacy_url_form"
    | "country_code2"
    | "country"
    | "region"
  >,
  location?: LocationSuggestion | NapiLocation
) {
  const place: SearchPlace = {
    city: {
      id: city.id,
      full_name: city.full_name,
      geohash: city.geohash,
      timezone: city.timezone,
      place_type: city.place_type,
      city_url: city.legacy_url_form || "",
      country_code2: city.country_code2,
      country: city.country,
      region: city.region
    }
  };

  if (location) {
    place.location = {
      id: normalizeLocationId(location.id),
      stop_type: location.type,
      place_type: location.place_type,
      full_name: location.name || ""
    };
    if ("geo_entity_id" in location) {
      place.location.geo_entity_id = location.geo_entity_id;
    }
  }

  return place;
}

export function normalizeLocationId(id: number | string | (string | number)[]) {
  return Array.isArray(id)
    ? (id as (string | number)[]).map(String)
    : [`${id}`];
}

export function placeToSuggestion(place: SearchPlace | null) {
  if (!place) {
    return null;
  }

  const { city, location } = place;
  const suggestion: PartialSuggestion = {
    country: "",
    country_code2: city.country_code2 || "",
    region_code: city.region.region_code || "",
    full_name: city.full_name
  };

  if (location) {
    suggestion.location = {
      country: "",
      country_code2: location.country_code2 || "",
      region_code: city.region.region_code || "",
      full_name: location.full_name
    };
  }

  return suggestion;
}

export function suggestionToPlace(suggestion: CitySuggestion): SearchPlace {
  const {
    location,
    city_id,
    city_url,
    full_name,
    geohash,
    geo_entity_id,
    place_type,
    timezone,
    request_id,
    country_code2,
    region_code,
    country
  } = suggestion;

  const place: SearchPlace = {
    request_id,
    city: {
      id: city_id,
      url: city_url,
      geo_entity_id,
      full_name,
      place_type,
      timezone,
      geohash,
      country_code2,
      country,
      region: {
        region_code: region_code || ""
      }
    }
  };

  if (location) {
    place.location = {
      country_code2,
      id: normalizeLocationId(location.id),
      geo_entity_id: location.geo_entity_id,
      place_type: location.place_type,
      stop_type: location.stop_type,
      full_name: location.full_name
    };
  }

  return place;
}

export function napiCitySuggestionToSuggestion(
  napi_suggestion: PartialSuggestion
): PartialSuggestion | null {
  if (napi_suggestion === null) {
    return null;
  }

  return {
    country: napi_suggestion.country,
    country_code2: napi_suggestion.country_code2,
    region_code: napi_suggestion.region_code || "",
    full_name: napi_suggestion.full_name,
    location: napi_suggestion.location
      ? {
          country: napi_suggestion.country,
          country_code2: napi_suggestion.country_code2,
          region_code: napi_suggestion.region_code || "",
          full_name: napi_suggestion.location.full_name
        }
      : undefined
  };
}
