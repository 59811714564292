import type { ChangeEventHandler } from "react";
import React, { useRef } from "react";

import { Input } from "@busbud/horizon";

import { ListBox } from "@app/components/listbox";
import { AutocompleteDataset } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/autocomplete-dataset";
import type { AutocompletePredictionDatasetOptions } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/autocomplete-input-hydrated";
import { useAutocompleteInput } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-autocomplete-input";
import { useEventListener } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-event-listener";
import { openModal } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-modal-state";
import type { NapiFlexLocationOption } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-napi-predictions";
import { useIsSearchWebComponent } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-search-widget-state";
import { WithSpinner } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/with-spinner";
import type { SearchFormValues } from "@app/components/search-form-hydrated/search-form-hydrated";
import { useLiteAppContext } from "@app/helpers/hooks";
import type { SearchLocationType } from "@app/types/search-types";

import type { UseFormGetValues } from "react-hook-form";

interface AutocompleteDropdownProps
  extends Omit<React.ComponentProps<typeof Input>, "type"> {
  type: SearchLocationType;
  autocomplete_dataset_options: AutocompletePredictionDatasetOptions;
  open: boolean;
  is_fetching: boolean;
  onOptionFocus: (option: NapiFlexLocationOption) => void;
  onOptionSelect: (option: NapiFlexLocationOption) => void;
  onClose: () => void;
  getValues: UseFormGetValues<SearchFormValues>;
  value: string; // TODO: remove after prop added to `Input`
}

export const AutocompleteInputDropdown = React.forwardRef<
  HTMLInputElement,
  AutocompleteDropdownProps
>(
  (
    {
      type,
      autocomplete_dataset_options,
      open,
      onClose,
      onChange,
      value,
      is_fetching,
      onOptionFocus,
      onOptionSelect,
      getValues,
      ...otherProps
    },
    forwardRef
  ) => {
    const { suggestions, recent_searches } = autocomplete_dataset_options;
    const { liteTranslator } = useLiteAppContext();
    const dropdownRef = useRef<HTMLDivElement>(null);
    const is_web_component = useIsSearchWebComponent();

    const has_options: boolean =
      !!suggestions.location_options.length ||
      !!recent_searches.location_options.length;
    const show_dropdown = open && (has_options || is_fetching);

    const placeholder =
      type === "origin"
        ? liteTranslator.t("!landing.input-label.origin")
        : liteTranslator.t("!landing.input-label.destination");

    // Close dropdown when clicking outside both the dropdown and the input
    useEventListener("mousedown", e => {
      if (is_web_component) {
        return;
      }

      for (const ref of [dropdownRef, forwardRef] as const) {
        if (
          !ref ||
          !("current" in ref) ||
          !ref.current ||
          !(e.target instanceof Element) ||
          ref.current.contains(e.target)
        ) {
          return;
        }
      }

      onClose();
    });

    const {
      handleOptionClick,
      handleOptionFocus,
      focusedOption,
      setFocusedOption
    } = useAutocompleteInput({
      location_type: type,
      onOptionSelect,
      onOptionFocus,
      getValues
    });

    const handleInputChange: ChangeEventHandler<HTMLInputElement> = e => {
      openModal(type); // Ensure the input is open when typing
      onChange?.(e);
      setFocusedOption(null);
    };

    return (
      <div id={`${type}-dropdown`} className="h-full w-full">
        <Input
          ref={forwardRef}
          id={`${type}-city-input`} // Used as target for `SkipLink` in `AboveTheFold`
          className="no-background-rest h-full w-full"
          autoComplete="off"
          type="text"
          value={value}
          placeholder={placeholder}
          onChange={handleInputChange}
          {...otherProps}
        />
        {!!show_dropdown && (
          <div
            data-testid={`${type}-dropdown-container`}
            ref={dropdownRef}
            className="js-suggestions-container absolute z-[1300] w-[21rem] rounded-lg bg-color-elevation-floating-canvas p-250 shadow-elevation-floating"
          >
            <ListBox>
              <WithSpinner isFetching={is_fetching}>
                <AutocompleteDataset
                  location_options={recent_searches.location_options}
                  label={liteTranslator.t(
                    "!search.input.location.recent.label"
                  )}
                  is_recent_search={!!recent_searches.location_options.length}
                  with_footer={!!suggestions.location_options.length}
                  focused_option={focusedOption}
                  handleOptionClick={handleOptionClick}
                  handleOptionFocus={handleOptionFocus}
                />
                <AutocompleteDataset
                  location_options={suggestions.location_options}
                  label={liteTranslator.t(
                    "!search.input.location.suggestion.label"
                  )}
                  focused_option={focusedOption}
                  handleOptionClick={handleOptionClick}
                  handleOptionFocus={handleOptionFocus}
                />
              </WithSpinner>
            </ListBox>
          </div>
        )}
      </div>
    );
  }
);
