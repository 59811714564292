import React, { useState } from "react";

import { openModal } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-modal-state";
import type { NapiFlexLocationOption } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-napi-predictions";
import { useSearchStateActions } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-search-state-hooks";
import type { SearchFormValues } from "@app/components/search-form-hydrated/search-form-hydrated";
import { useLiteAppContext } from "@app/helpers/hooks";
import { suggestionToPlace } from "@app/modules/search/helpers";
import { openedSearchDestination } from "@app/tracking/search-tracking";
import type { SearchLocationType } from "@app/types/search-types";
import { yieldToMain } from "@app/utils/scheduler-yield";

import type { UseFormGetValues } from "react-hook-form";

interface AutocompleteInputOptions {
  location_type: SearchLocationType;
  onOptionSelect: (option: NapiFlexLocationOption) => void;
  onOptionFocus: (option: NapiFlexLocationOption) => void;
  getValues: UseFormGetValues<SearchFormValues>;
}

export const useAutocompleteInput = ({
  location_type,
  onOptionSelect,
  onOptionFocus,
  getValues
}: AutocompleteInputOptions) => {
  const { tracker } = useLiteAppContext();
  const { setRecentOrigin, setOrigin, setRecentDestination, setDestination } =
    useSearchStateActions();
  const [focusedOption, setFocusedOption] = useState<string | null>(null);
  const is_origin = location_type === "origin";

  const handleOptionClick = React.useCallback(
    async (option: NapiFlexLocationOption) => {
      const setRecent = is_origin ? setRecentOrigin : setRecentDestination;
      const setPlace = is_origin ? setOrigin : setDestination;

      await yieldToMain();

      setRecent(option.value);
      setPlace(suggestionToPlace(option.value));
      onOptionSelect(option);

      if (is_origin && !getValues("destination")) {
        yieldToMain().then(() => {
          openModal("destination");
          tracker?.asyncTrack(openedSearchDestination());
        });
      }
    },
    [
      setRecentOrigin,
      setOrigin,
      setRecentDestination,
      setDestination,
      is_origin,
      onOptionSelect,
      getValues,
      tracker
    ]
  );

  const handleOptionFocus = React.useCallback(
    async (option: NapiFlexLocationOption) => {
      await yieldToMain();
      const setPlace = is_origin ? setOrigin : setDestination;

      setFocusedOption(option.id);
      onOptionFocus(option);
      setPlace(suggestionToPlace(option.value));
    },
    [is_origin, setOrigin, setDestination, onOptionFocus]
  );

  return {
    handleOptionClick,
    handleOptionFocus,
    focusedOption,
    setFocusedOption
  };
};
