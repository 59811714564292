import React from "react";

import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { AffiliateCheckbox } from "@app/components/landing-pages/affiliate-checkbox";
import type { SearchFormStyleVariant } from "@app/components/search-form/search-form";
import { SearchFormContainer } from "@app/components/search-form/search-form-container";
import { openModal } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-modal-state";
import {
  getLegacySearchStateValues,
  useAffiliateOptin,
  useDefaultSearchLocationValues,
  useLegacySearchFormPassengers,
  useSearchLocationValues
} from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-search-state-hooks";
import {
  ancillaryRedirectAffiliateAndShowBusbudResults,
  redirectToResultsPage,
  redirectWebComponentToResultsPage
} from "@app/components/search-form-hydrated/autocomplete-input-hydrated/search-redirect-helpers";
import type { CompactConfiguration } from "@app/components/search-form-hydrated/search-form-date-passenger-submit-section";
import { SearchFormDatePassengerSubmitSection } from "@app/components/search-form-hydrated/search-form-date-passenger-submit-section";
import { SearchFormDevTools } from "@app/components/search-form-hydrated/search-form-devtools";
import { SearchFormOriginDestinationSection } from "@app/components/search-form-hydrated/search-form-origin-destination-section";
import { shouldIncreaseReturnDate } from "@app/components/search-form-hydrated/search-form.helpers";
import { useWhitelabelContext } from "@app/context/whitelabel-context";
import { getPopUnderAffiliate } from "@app/helpers/affiliate";
import { useLiteAppContext } from "@app/helpers/hooks";
import { LocalStorage } from "@app/lib/bowser-storage/local-storage";
import { SEARCH_ERRORS } from "@app/modules/search/constants";
import { saveRecentSearch } from "@app/modules/search/helpers/recent-searches";
import { getSearchQuery } from "@app/modules/search/helpers/submit";
import { useDateInputs } from "@app/modules/search/store/hooks/date-inputs";
import { usePassengers } from "@app/modules/search/store/hooks/passengers";
import { isMissingAges } from "@app/modules/search/store/selectors/passengers";
import {
  setOutboundDate,
  setReturnDate
} from "@app/modules/search/store/slices/search-form";
import {
  clickedAffiliateCheckbox,
  failedRouteSearch,
  searchedRoute,
  selectedDepartureDate,
  selectedReturnDate,
  unselectedReturnDate
} from "@app/tracking/search-tracking";
import type { SearchFormMergedState } from "@app/types/search-types";
import {
  addDaysFormatted,
  getTomorrowDateFormatted
} from "@app/utils/dates-without-moment";
import { yieldToMain } from "@app/utils/scheduler-yield";
import { appendQueryToCurrentLocation } from "@app/utils/window";

import type { SubmitHandler } from "react-hook-form";

import { useIsSearchWebComponent } from "./autocomplete-input-hydrated/hooks/use-search-widget-state";

export interface SearchFormValues {
  origin: string;
  destination: string;
  outbound_date: string;
  return_date: string;
  affiliate_optin: boolean;
}

export interface Props {
  compact_configuration: CompactConfiguration;
  search_label: string;
  one_way_only?: boolean;
  with_affiliate_checkbox?: boolean;
  affiliate_checked?: boolean;
  style_variant?: SearchFormStyleVariant;
  className?: string;
}

export const SearchFormHydrated: React.FC<Props> = ({
  compact_configuration,
  search_label,
  style_variant,
  with_affiliate_checkbox = false,
  affiliate_checked = false,
  one_way_only = false,
  ...props
}) => {
  const { features, tracker } = useLiteAppContext();
  const dispatch = useDispatch();
  const { whitelabel: dynamic_whitelabel } = useWhitelabelContext();
  const is_web_component = useIsSearchWebComponent();
  const isMountedRef = React.useRef(false);
  const { return_date, outbound_date } = useDateInputs();

  const { passengers } = usePassengers();

  const legacy_passenger_state = useLegacySearchFormPassengers();

  const { origin, destination } = useDefaultSearchLocationValues();
  const initial_locations = React.useRef({
    initial_origin: origin.value,
    initial_destination: destination.value
  });
  const { recent_searches } = useSearchLocationValues();

  const affiliate_optin_redux = useAffiliateOptin();

  const { handleSubmit, control, setValue, getValues } =
    useForm<SearchFormValues>({
      defaultValues: {
        origin: origin.formatted,
        destination: destination.formatted,
        outbound_date: outbound_date || "",
        return_date: return_date || "",
        affiliate_optin: affiliate_checked
      }
    });

  React.useEffect(
    function syncOutboundDateInputValues() {
      const prev_date = getValues("outbound_date");
      let new_outbound_date = outbound_date;

      if (!isMountedRef.current) {
        if (!new_outbound_date) {
          new_outbound_date = getTomorrowDateFormatted();
          dispatch(setOutboundDate(new_outbound_date));
        }
        isMountedRef.current = true;
      }

      if (!new_outbound_date) {
        return;
      }

      setValue("outbound_date", new_outbound_date, {
        shouldDirty: true
      });

      const return_date = getValues("return_date");
      if (shouldIncreaseReturnDate(new_outbound_date, return_date)) {
        dispatch(setReturnDate(addDaysFormatted(new_outbound_date, 1)));
      }

      if (prev_date && prev_date !== new_outbound_date) {
        tracker?.asyncTrack(
          selectedDepartureDate(prev_date, new_outbound_date)
        );
      }
    },
    [outbound_date, setValue, getValues, tracker, dispatch]
  );

  React.useEffect(
    function syncReturnDateInputValues() {
      const prev_date = getValues("return_date");
      setValue("return_date", return_date || "", {
        shouldDirty: true
      });

      if (return_date) {
        tracker?.asyncTrack(selectedReturnDate(prev_date ?? null, return_date));
      } else if (prev_date && !return_date) {
        tracker?.asyncTrack(unselectedReturnDate());
      }
    },
    [return_date, setValue, getValues, tracker]
  );

  React.useEffect(
    function syncAffiliateInputValue() {
      // If there's no checkbox, don't enable the affiliate optin
      if (!with_affiliate_checkbox) {
        setValue("affiliate_optin", false);
        return;
      }
      setValue("affiliate_optin", affiliate_optin_redux);
    },
    [with_affiliate_checkbox, affiliate_optin_redux, setValue]
  );

  const onSubmit: SubmitHandler<SearchFormValues> = async data => {
    const { outbound_date, return_date, affiliate_optin } = data;
    const legacy_state_values = getLegacySearchStateValues();
    const { lang, whitelabel, push_state_disabled } = legacy_state_values;

    const state: Omit<SearchFormMergedState, "passengers"> = {
      origin: origin.value,
      destination: destination.value,
      affiliate_optin,
      outbound_date,
      return_date,
      vehicle_category: undefined,
      ...legacy_passenger_state,
      ...legacy_state_values
    };

    if (!(origin.value && destination.value && outbound_date)) {
      return;
    }

    const search_with_accomodation_affiliate = affiliate_optin
      ? getPopUnderAffiliate()
      : "none";

    await yieldToMain();

    if (
      isMissingAges({
        search_form: {
          passengers
        }
      })
    ) {
      openModal("passengers");
      tracker?.asyncTrack(
        failedRouteSearch({
          state: {
            ...state,
            origin: origin.value,
            destination: destination.value,
            outbound_date
          },
          errors: [SEARCH_ERRORS.MISSING_AGE],
          search_with_accomodation_affiliate
        })
      );
      return;
    }

    const results_options = {
      lang,
      features,
      whitelabel: whitelabel || dynamic_whitelabel?.name,
      state,
      tracker
    };

    const browser_storage = LocalStorage.fromWindow(window);

    saveRecentSearch(browser_storage, "origin", recent_searches.origin);
    saveRecentSearch(
      browser_storage,
      "destination",
      recent_searches.destination
    );

    // Tracking
    const { initial_origin, initial_destination } = initial_locations.current;
    tracker?.track(
      searchedRoute({
        state: {
          ...state,
          origin: origin.value,
          destination: destination.value,
          outbound_date
        },
        initial_origin,
        initial_destination,
        search_with_accomodation_affiliate
      })
    );

    // Search form widget
    if (is_web_component) {
      redirectWebComponentToResultsPage(results_options);
      return;
    }

    // Redirect
    if (!push_state_disabled) {
      // This allows us to fill the form when the user clicks back from results page
      appendQueryToCurrentLocation(getSearchQuery(results_options, state));
    }

    if (search_with_accomodation_affiliate !== "none") {
      await ancillaryRedirectAffiliateAndShowBusbudResults(
        search_with_accomodation_affiliate,
        results_options
      );
      return;
    }

    redirectToResultsPage(results_options);
  };

  return (
    <>
      <SearchFormContainer
        data-testid="search-form-hydrated"
        onSubmit={handleSubmit(onSubmit)}
        style_variant={style_variant}
        {...props}
      >
        <SearchFormOriginDestinationSection
          control={control}
          setValue={setValue}
          getValues={getValues}
          one_way_only={one_way_only}
        />
        <SearchFormDatePassengerSubmitSection
          search_label={search_label}
          compact_configuration={compact_configuration}
          one_way_only={one_way_only}
          control={control}
        />
      </SearchFormContainer>
      {!!with_affiliate_checkbox && (
        <Controller
          control={control}
          name="affiliate_optin"
          render={({ field: { ref, value, onChange, ...field } }) => (
            <AffiliateCheckbox
              className="mt-150"
              isDefaultChecked={value}
              onChange={e => {
                onChange(e);
                tracker?.asyncTrack(
                  clickedAffiliateCheckbox(
                    getPopUnderAffiliate(),
                    !!(e?.currentTarget as HTMLInputElement).checked
                  )
                );
              }}
              {...field}
            />
          )}
        />
      )}
      <SearchFormDevTools control={control} />
    </>
  );
};
