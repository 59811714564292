import type { PropsWithChildren } from "react";
import React from "react";

import clsx from "clsx";

import { TwCommonHeaderStyles } from "@app/components/header/header.styles";
import type { SearchFormStyleVariant } from "@app/components/search-form/search-form";

export const SEARCH_FORM_ID = "search-form";

export const search_form_container_classes = {
  container:
    "group flex flex-wrap lg:rounded-md lg:bg-color-canvas-primary lg:shadow-md"
} satisfies Record<string, string>;

interface SearchFormContainerProps
  extends PropsWithChildren<
    React.DetailedHTMLProps<
      React.FormHTMLAttributes<HTMLFormElement>,
      HTMLFormElement
    >
  > {
  style_variant?: SearchFormStyleVariant;
}

const search_form_container_styles: Record<
  SearchFormStyleVariant,
  Parameters<typeof clsx>
> = {
  default: [],
  collapse: [
    "transition-all duration-500 ease-in-out",
    "group-[.is-expanded]:mb-200 group-[.is-expanded]:mt-100 group-[.is-expanded]:flex group-[.is-expanded]:max-h-[400px] group-[.is-expanded]:opacity-100",
    "group-[.is-collapsed]:max-h-0 group-[.is-collapsed]:overflow-hidden group-[.is-collapsed]:opacity-0"
  ],
  form_root: [TwCommonHeaderStyles.formRoot],
  form_root_date_tabs: [
    TwCommonHeaderStyles.formRoot,
    TwCommonHeaderStyles.showDateTabs
  ],
  full_width: ["w-full"]
};

export const SearchFormContainer: React.FC<SearchFormContainerProps> = ({
  children,
  className,
  style_variant = "default",
  ...props
}) => {
  const style_classes = search_form_container_styles[style_variant];

  return (
    <form
      id={SEARCH_FORM_ID}
      noValidate
      className={clsx(
        search_form_container_classes.container,
        style_classes,
        className
      )}
      {...props}
    >
      {children}
    </form>
  );
};
