import { useEffect, useMemo, useRef, useState } from "react";

import { getFormattedSuggestionName } from "@app/components/search-form/helpers";
import type { AutocompletePredictionDataset } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-napi-predictions";
import { getFormattedPlaceUniqueId } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-napi-predictions";
import type { FormattedSuggestion } from "@app/modules/search/helpers/format-suggestion";
import type { SearchLocationType } from "@app/types/search-types";

function getStorageValue<T>(key: string, defaultValue: T): T {
  try {
    if (typeof localStorage === "undefined") {
      return defaultValue;
    }

    const saved = localStorage.getItem(key);
    const initial = saved ? (JSON.parse(saved) as T) : null;
    return initial || defaultValue;
  } catch {
    // Catch to handle error: Failed to read the 'localStorage' property from 'Window': Access is denied for this document.
    return defaultValue;
  }
}

const useLocalStorage = <T>(key: string, defaultValue: T) => {
  const [value, setValue] = useState(() => {
    return getStorageValue<T>(key, defaultValue);
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue] as const;
};

export const useRecentSearches = (
  place: SearchLocationType,
  query: string = ""
): AutocompletePredictionDataset => {
  const default_value = useRef<FormattedSuggestion[]>([]).current;
  const [recent_searches] = useLocalStorage<FormattedSuggestion[]>(
    `recent_searches_${place}`,
    default_value
  );

  return useMemo(() => {
    const filtered_recent_searches = recent_searches
      .filter(search => {
        return (
          search.location?.full_name
            .toLowerCase()
            .includes(query.toLowerCase()) ||
          search.full_name.toLowerCase().includes(query.toLowerCase())
        );
      })
      .map(formatted_suggestion => ({
        id: getFormattedPlaceUniqueId(formatted_suggestion),
        value: formatted_suggestion,
        label: getFormattedSuggestionName(formatted_suggestion)
      }));

    return {
      location_options: filtered_recent_searches
    };
  }, [query, recent_searches]);
};
