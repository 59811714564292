/*
moment is a heavy library and we don't want to load it on the landing pages.
As the tree shaking on our files does not work very well on the landing pages,
it's easier to just split dates utils in 2 files
 */

// Output format YYYY-MM-DD
import type { IsoDate } from "@app/components/calendar/Calendar.types";

export function formatToUtcDate(date: Date): IsoDate {
  const year = date.getUTCFullYear();
  const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
  const day = ("0" + date.getUTCDate()).slice(-2);

  return `${year}-${month}-${day}`;
}

// Output format YYYY-MM-DD
export function formatToLocalDate(date: Date): string {
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);

  return `${year}-${month}-${day}`;
}

// Output format YYYY-MM-DD
export const addDaysFormatted = (
  current_date: string,
  days: number
): string => {
  const [year, month, day] = current_date
    .slice(0, "YYYY-MM-DD".length)
    .split("-");
  const date = Date.UTC(
    parseInt(year),
    parseInt(month) - 1,
    parseInt(day) + days
  );
  return formatToUtcDate(new Date(date));
};

/**
 *  We don't want to use moment to ensure the landing-page bundle
 *  is as small as possible
 *
 * @param date format is YYYY-MM-DD
 * @param first_day_of_week 0 is sunday
 */
export function getStartOfFirstWeekOfTheMonth(
  date: string,
  first_day_of_week: number
): Date {
  const start = new Date(date);
  start.setUTCDate(1);
  const start_date_offset = 1 + first_day_of_week - start.getUTCDay();
  start.setUTCDate(
    start_date_offset > 1 ? start_date_offset - 7 : start_date_offset
  );

  return start;
}

/**
 *  We don't want to use moment to ensure the landing-page bundle
 *  is as small as possible
 *
 * @param date format is YYYY-MM-DD
 * @param first_day_of_week 0 is sunday
 */
export function getEndOfLastWeekOfTheMonth(
  date: IsoDate,
  first_day_of_week: number
): Date {
  const last_day_of_week = mod(first_day_of_week - 1, 7);
  const end = new Date(date);
  const original_utc_month = end.getUTCMonth();
  end.setUTCDate(1);
  end.setUTCMonth(original_utc_month + 1);
  end.setUTCDate(0);
  const last_day_of_month_day = end.getUTCDay();
  if (last_day_of_month_day !== last_day_of_week) {
    const new_end = new Date(date);
    new_end.setUTCDate(1);
    new_end.setUTCMonth(original_utc_month + 1);
    new_end.setUTCDate(
      1 + ((6 + last_day_of_week - last_day_of_month_day) % 7)
    );
    return new_end;
  }

  return end;
}

// Cusotm modulo function to avoid negative remainder problem
// cf. https://stackoverflow.com/questions/4467539/javascript-modulo-gives-a-negative-result-for-negative-numbers
function mod(n: number, m: number) {
  return ((n % m) + m) % m;
}

export function getUTCDate(date: string | Date): Date {
  const new_date = new Date(date);
  const offset = new_date.getTimezoneOffset() * 60000;
  return new Date(new_date.getTime() + offset);
}

export const getTomorrowDateFormatted = (timezone?: string): string => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  if (timezone) {
    const year_formatter = new Intl.DateTimeFormat("en-us", {
      timeZone: timezone,
      year: "numeric"
    });
    const month_formatter = new Intl.DateTimeFormat("en-us", {
      timeZone: timezone,
      month: "2-digit"
    });
    const day_formatter = new Intl.DateTimeFormat("en-us", {
      timeZone: timezone,
      day: "2-digit"
    });

    return (
      year_formatter.format(tomorrow) +
      "-" +
      month_formatter.format(tomorrow) +
      "-" +
      day_formatter.format(tomorrow)
    );
  }

  return formatToUtcDate(tomorrow);
};
