import React from "react";

import { Controller } from "react-hook-form";

import { LocationGroup } from "@app/components/search-form/location-group";
import { AutocompleteInputHydrated } from "@app/components/search-form-hydrated/autocomplete-input-hydrated";
import type { SearchFormValues } from "@app/components/search-form-hydrated/search-form-hydrated";
import { SwapOriginDestinationButton } from "@app/components/search-form-hydrated/swap-origin-destination-button";
import { useLiteAppContext } from "@app/helpers/hooks";

import type {
  Control,
  UseFormGetValues,
  UseFormSetValue
} from "react-hook-form";

interface SearchFormOriginDestinationSectionProps {
  one_way_only: boolean;
  control: Control<SearchFormValues>;
  setValue: UseFormSetValue<SearchFormValues>;
  getValues: UseFormGetValues<SearchFormValues>;
}
export const SearchFormOriginDestinationSection = React.memo(
  ({
    one_way_only,
    control,
    setValue,
    getValues
  }: SearchFormOriginDestinationSectionProps) => {
    const { entity, features } = useLiteAppContext();

    const is_results = !!(entity && entity.type === "results");
    const is_disabled =
      is_results && !!features.SEARCH_FORM_DISABLE_LOCATION_INPUTS_ON_RESULTS;

    return (
      <LocationGroup
        one_way_only={one_way_only}
        originInputSection={
          <Controller
            control={control}
            name="origin"
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <AutocompleteInputHydrated
                type="origin"
                isInvalid={!!fieldState.error}
                getValues={getValues}
                isDisabled={is_disabled}
                {...field}
              />
            )}
          />
        }
        swapLocationSection={
          <SwapOriginDestinationButton
            getValues={getValues}
            setValue={setValue}
            is_disabled={is_disabled}
          />
        }
        destinationInputSection={
          <Controller
            control={control}
            name="destination"
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <AutocompleteInputHydrated
                type="destination"
                isInvalid={!!fieldState.error}
                getValues={getValues}
                isDisabled={is_disabled}
                {...field}
              />
            )}
          />
        }
      />
    );
  }
);
