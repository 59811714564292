import { createSlice } from "@reduxjs/toolkit";

import type { SearchReduxState } from "@app/types/search-types";

import type { PayloadAction } from "@reduxjs/toolkit";

const setOutboundDateReducer = (
  _state: SearchReduxState["outbound_date"],
  action: PayloadAction<SearchReduxState["outbound_date"]>
) => action.payload;

const initial_state = null as SearchReduxState["outbound_date"];

const OutboundDate = createSlice({
  name: "search_form/outbound_date",
  initialState: initial_state,
  reducers: {
    setOutboundDate: setOutboundDateReducer
  }
});

export const { setOutboundDate } = OutboundDate.actions;
export default OutboundDate.reducer;
