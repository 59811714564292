import React from "react";

import { PlaceType } from "@busbud/core-types/dist/lib/flex";

import { ListBox } from "@app/components/listbox";
import { getSuggestionLocationIcon } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/autocomplete-option/autocomplete-icons";
import type { NapiFlexLocationOption } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-napi-predictions";
import { useLiteAppContext } from "@app/helpers/hooks";
import type { SuggestionIconType } from "@app/modules/search/suggestion/suggestion-icon";
import { SuggestionIcon } from "@app/modules/search/suggestion/suggestion-icon";
import type { Features } from "@app/types/experiments";

interface CitySuggestionItemProps extends React.HTMLAttributes<HTMLDivElement> {
  suggestion: NapiFlexLocationOption;
  isActive: boolean;
}

const getTitle = (
  suggestion: NapiFlexLocationOption,
  features: Partial<Features>
): {
  title: string;
  subtitle: string | undefined;
  detail: string | undefined;
} => {
  const { full_name, location } = suggestion.value;

  if (
    features.SUGGESTIONS_POI_TEMPLATE &&
    location &&
    location.place_type === PlaceType.PointOfInterest
  ) {
    return {
      title: location.full_name,
      subtitle: undefined,
      detail: full_name
    };
  }

  const [city_name, ...region_name] = full_name.split(",");
  return {
    title: city_name,
    subtitle: region_name.join(", "),
    detail: location?.full_name
  };
};

export const CitySuggestionItem = React.forwardRef<
  HTMLDivElement,
  CitySuggestionItemProps
>(({ suggestion, ...props }, ref) => {
  const { features } = useLiteAppContext();
  const { title, subtitle, detail } = getTitle(suggestion, features);
  const { location } = suggestion.value;

  let icon: SuggestionIconType = "city";

  if (location) {
    icon = getSuggestionLocationIcon(location.stop_type);
  }

  return (
    <ListBox.Option
      iconStart={<SuggestionIcon type={icon} hidden={false} />}
      title={title}
      subtitle={subtitle}
      detail={detail}
      ref={ref}
      {...props}
    />
  );
});
