import type {
  PropsWithChildren,
  ReactNode,
  ForwardRefExoticComponent,
  RefAttributes,
  FC
} from "react";
import React, { forwardRef } from "react";

import clsx from "clsx";

import type { DividerProps } from "@busbud/horizon";
import { Divider, Label, mergeClasses } from "@busbud/horizon";

/*
 * ListBox
 */

export interface ListBoxProps {
  className?: string;
}

export interface ListBoxComponent
  extends ForwardRefExoticComponent<
    PropsWithChildren<ListBoxProps> & RefAttributes<HTMLDivElement>
  > {
  Option: typeof ListBoxOption;
  OptGroup: typeof ListBoxOptGroup;
  Divider: typeof ListBoxDivider;
}

export const ListBox = forwardRef<
  HTMLDivElement,
  PropsWithChildren<ListBoxProps>
>(({ children, className, ...restProps }, ref) => {
  return (
    <div
      className={clsx("flex flex-col", className)}
      role="listbox"
      ref={ref}
      {...restProps}
    >
      {children}
    </div>
  );
}) as ListBoxComponent;

/*
 * ListBoxOption
 */

export interface ListBoxOptionProps {
  className?: string;
  iconEnd?: ReactNode;
  iconStart?: ReactNode;
  isActive?: boolean;
  subtitle?: string;
  title: string;
  detail?: string;
  isChild?: boolean;
}

export const ListBoxOption = forwardRef<
  HTMLDivElement,
  PropsWithChildren<ListBoxOptionProps>
>(
  (
    {
      className,
      iconEnd,
      iconStart,
      subtitle,
      title,
      isActive,
      detail,
      isChild,
      ...restProps
    },
    ref
  ) => {
    return (
      <div
        className={mergeClasses(
          clsx(
            "flex cursor-pointer items-center rounded-md p-150 hover:bg-color-scheme-interactive-selected-100",
            {
              "bg-color-scheme-interactive-selected-100": isActive,
              "ml-400": isChild
            }
          ),
          className
        )}
        ref={ref}
        role="option"
        aria-selected={isActive}
        {...restProps}
      >
        {!!iconStart && (
          <div className="shrink-0 text-icon-color-primary">{iconStart}</div>
        )}
        <div className="flex select-none flex-col gap-050 overflow-hidden py-025 pe-100 ps-100">
          <div className="flex">
            <Label
              as="span"
              fontWeight={isChild ? "regular" : "bold"}
              size={isChild ? "md" : "lg"}
              noWrap
            >
              {title}
            </Label>
            {!!subtitle && (
              <Label
                as="span"
                size="md"
                noWrap
                className="ml-100 overflow-hidden text-color-tertiary"
              >
                {subtitle}
              </Label>
            )}
          </div>
          {!!detail && (
            <Label as="span" className="w-full text-color-secondary" size="md">
              {detail}
            </Label>
          )}
        </div>
        {!!iconEnd && (
          <div className="shrink-0 text-icon-color-primary">{iconEnd}</div>
        )}
      </div>
    );
  }
);

/*
 * ListBoxOptGroup
 */

export interface ListBoxOptGroupProps {
  className?: string;
  title?: string;
}

export const ListBoxOptGroup: FC<PropsWithChildren<ListBoxOptGroupProps>> = ({
  children,
  className,
  title,
  ...restProps
}) => {
  return (
    <div
      className={mergeClasses("[&:not(:first-child)]:mt-100", className)}
      role="group"
      {...restProps}
    >
      {!!title && (
        <Label className="mb-100" as="div" fontWeight="bold">
          {title}
        </Label>
      )}
      {children}
    </div>
  );
};

/*
 * Divider
 */

export const ListBoxDivider: FC<DividerProps> = ({
  className,
  ...restProps
}) => <Divider className={clsx("my-100", className)} {...restProps} />;

ListBox.Option = ListBoxOption;
ListBox.OptGroup = ListBoxOptGroup;
ListBox.Divider = ListBoxDivider;
