import React from "react";

import { ListBox } from "@app/components/listbox";
import { getSuggestionLocationIcon } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/autocomplete-option/autocomplete-icons";
import type { NapiFlexLocationOption } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-napi-predictions";
import type { SuggestionIconType } from "@app/modules/search/suggestion/suggestion-icon";
import { SuggestionIcon } from "@app/modules/search/suggestion/suggestion-icon";

interface LocationSuggestionItemProps
  extends React.HTMLAttributes<HTMLDivElement> {
  suggestion: NapiFlexLocationOption;
}

export const LocationSuggestionItem = React.forwardRef<
  HTMLDivElement,
  LocationSuggestionItemProps
>(({ suggestion, ...props }, ref) => {
  const { location } = suggestion.value;
  const icon: SuggestionIconType = getSuggestionLocationIcon(
    location?.stop_type
  );
  if (!location) {
    return null;
  }
  return (
    <ListBox.Option
      iconStart={<SuggestionIcon size="sm" type={icon} hidden={false} />}
      title={location?.full_name}
      isChild
      ref={ref}
      {...props}
    />
  );
});
