import * as React from "react";

import { Input } from "@busbud/horizon";

import { useIntlProvider } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-intl-provider";
import { openModal } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-modal-state";
import CalendarModal from "@app/components/search-form-hydrated/calendar-modal";
import { useLiteAppContext } from "@app/helpers/hooks";
import { useFastClick } from "@app/modules/search/use-fast-click";
import { clickedSearchDate } from "@app/tracking/search-tracking";
import { yieldToMain } from "@app/utils/scheduler-yield";

type SearchFormDateInputProps = {
  id: string;
  labelText: string;
  value: string;
  placeholder: string;
  direction: "outbound" | "return";
};
export const SearchFormDateInput = ({
  id,
  labelText,
  value,
  direction,
  placeholder
}: SearchFormDateInputProps) => {
  const { getLocalizedDate } = useIntlProvider();
  const { tracker } = useLiteAppContext();

  const handleDateClick = React.useCallback(async () => {
    await yieldToMain();
    openModal(direction === "outbound" ? "outbound_date" : "return_date");

    tracker?.asyncTrack(clickedSearchDate(direction));
  }, [direction, tracker]);
  const fastClickRef = useFastClick<HTMLInputElement>(handleDateClick);

  return (
    <>
      <Input
        id={id}
        labelText={labelText}
        ref={fastClickRef}
        value={getLocalizedDate(value)}
        // Transition are slow on (old) mobile, and degrades our INP
        // We display a modal on top of the input anyway, removing the transition should be harmless
        className="no-background-rest h-full w-full max-lg:[&_*]:transition-none max-lg:[&_*]:before:transition-none"
        type="text"
        // An ‘em’ space symbol to prevent the floating label from triggering when the value is not ready.
        // This solution also addresses the specific behavior of the `:placeholder-shown` pseudo-class in Safari.
        // (refer to the styles of the `Field` for more details)
        placeholder={placeholder}
        isReadOnly
      />
      <CalendarModal
        direction={direction}
        input_element={fastClickRef}
        selected_date={value}
      />
    </>
  );
};
