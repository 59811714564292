import React from "react";

import { clsx } from "clsx";

import type { CheckboxProps } from "@busbud/horizon";
import { Checkbox } from "@busbud/horizon";

import { useSearchStateActions } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-search-state-hooks";
import { useLiteAppContext } from "@app/helpers/hooks";

export const AffiliateCheckbox = React.forwardRef<
  HTMLInputElement,
  CheckboxProps
>(({ className, ...props }, ref) => {
  const { liteTranslator } = useLiteAppContext();
  const { setAffiliateOptin } = useSearchStateActions();

  // TODO: remove when uncontrolled checkbox is supported
  React.useEffect(() => {
    const affiliate_checkbox = document.getElementById(
      "affiliate_checkbox"
    ) as HTMLInputElement | null;

    if (!affiliate_checkbox) {
      return;
    }

    const change_listener = (event: Event) => {
      const checkbox = event.currentTarget as HTMLInputElement;
      const new_checkbox_state: boolean = checkbox.checked;
      setAffiliateOptin(new_checkbox_state);
    };

    affiliate_checkbox.addEventListener("change", change_listener);

    setAffiliateOptin(affiliate_checkbox.checked); // set initial state after  back or refresh

    return () => {
      affiliate_checkbox.removeEventListener("change", change_listener);
    };
  }, [setAffiliateOptin]);

  return (
    <Checkbox
      ref={ref}
      className={clsx("self-start", className)}
      id="affiliate_checkbox"
      labelText={liteTranslator.t("!search.affiliates.checkbox-text")}
      data-cy="affiliate-checkbox"
      variant="solid"
      {...props}
    />
  );
});
