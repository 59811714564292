import { AffiliatePartners } from "@app/constants/affiliate-partners";

// To enable the feature to 5%, set the const to 0.05
// To disable the feature, set the const to -1
// const EXPEDIA_ACTIVATION_PERCENTAGE = 0.5; // 50%
const EXPEDIA_ACTIVATION_PERCENTAGE = -1; // feature off
const ALLOWED_COUNTRIES = ["US", "CA", "MX"];

export function getPopUnderAffiliate(): AffiliatePartners {
  const is_allowed_country = ALLOWED_COUNTRIES.includes(
    window.BB.user_country_code
  );
  const random_segment = window.BB.random_segment || 0;

  return random_segment > 0 &&
    random_segment <= EXPEDIA_ACTIVATION_PERCENTAGE &&
    is_allowed_country
    ? AffiliatePartners.EXPEDIA
    : AffiliatePartners.BOOKING_COM;
}
