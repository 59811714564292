import React from "react";

import { ListBox } from "@app/components/listbox";
import { AutocompleteOption } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/autocomplete-option/autocomplete-option";
import type { NapiFlexLocationOption } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-napi-predictions";

interface AutocompleteDatasetProps {
  location_options: NapiFlexLocationOption[];
  label: string;
  focused_option: string | null;
  is_recent_search?: boolean;
  with_footer?: boolean;
  handleOptionClick: (option: NapiFlexLocationOption) => void;
  handleOptionFocus: (option: NapiFlexLocationOption) => void;
}

export const AutocompleteDataset: React.FC<AutocompleteDatasetProps> =
  React.memo(
    ({
      location_options,
      label,
      focused_option,
      is_recent_search,
      with_footer = false,
      handleOptionClick,
      handleOptionFocus
    }) => {
      const has_options: boolean = location_options.length > 0;

      if (!has_options) {
        return null;
      }

      return (
        <ListBox.OptGroup title={label}>
          {location_options.map((option, index) => (
            <AutocompleteOption
              key={`${label}-${option.id}-${index}`}
              is_active={
                `${option.id}-${label}` === `${focused_option}-${label}`
              }
              suggestion={option}
              handleOptionClick={handleOptionClick}
              handleOptionFocus={handleOptionFocus}
              is_recent_search={is_recent_search}
            />
          ))}
          {!!with_footer && <ListBox.Divider />}
        </ListBox.OptGroup>
      );
    }
  );
