export const AffiliatePartners = {
  BOOKING_COM: "booking-com",
  CLICKTRIPZ: "clicktripz",
  EXPEDIA: "expedia"
} as const;

export type AffiliatePartners =
  (typeof AffiliatePartners)[keyof typeof AffiliatePartners];

export const BOOKING_COM_AID = {
  SEARCH: "1997859",
  CONFIRMATION_PAGE_BANNER: "2261544",
  CONFIRMATION_PAGE_REDIRECT: "2261543",
  RESULTS_PAGE_REDIRECT: "2261756",
  NAVIGATION_MENU: "2262103",
  CITY_PAGE_SEM: "2262105",
  ROUTE_PAGE_SEM: "2262106",
  CITY_PAGE_SEO: "2262107",
  ROUTE_PAGE_SEO: "2262106", // Temporary fallback. Waiting an exlusive AID
  SEARCH_WITH_EXPEDIA_COMPETITOR: "2265814"
} as const;

export const EXPEDIA_AID = {
  SEARCH: "US.DIRECT.PHG.1100l345934"
} as const;
