import { createSlice } from "@reduxjs/toolkit";

import type { PassengersCount } from "@app/modules/search/store/slices/search-form/passengers-constants";
import {
  ADULT,
  ADULT_STUDENT_CHECKBOXES,
  ADULT_WHEELCHAIR_CHECKBOXES,
  AGE_RANGES,
  CHILD,
  CHILD_AGES,
  SENIOR,
  SENIOR_AGES
} from "@app/modules/search/store/slices/search-form/passengers-constants";
import type { PassengerComplete } from "@app/types/search-types";

import type { PayloadAction } from "@reduxjs/toolkit";

import { assignAttributeToPassengerReducer } from "./reducers/assign-attribute-to-passenger";
import { removePassengerReducer } from "./reducers/remove-passsenger";
import { setPassengerAgeReducer } from "./reducers/set-passenger-age";
import { unassignAttributeToAllPassengersReducer } from "./reducers/unassign-attribute-to-all-passengers";
import { unassignAttributeToPassengerReducer } from "./reducers/unassign-attribute-to-passenger";

const PASSENGERS_COUNT: PassengersCount[] = [ADULT, CHILD, SENIOR];
const incrementPassengersCountReducer = (
  state: PassengerComplete,
  {
    payload
  }: PayloadAction<{
    category: PassengersCount;
  }>
) => {
  const { category } = payload;

  if (!PASSENGERS_COUNT.includes(category)) {
    return state;
  }

  const pax_ages = category === CHILD ? CHILD_AGES : SENIOR_AGES;

  const fields = {
    [ADULT_STUDENT_CHECKBOXES]: [...state[ADULT_STUDENT_CHECKBOXES]],
    [ADULT_WHEELCHAIR_CHECKBOXES]: [...state[ADULT_WHEELCHAIR_CHECKBOXES]],
    [pax_ages]: [...state[pax_ages]]
  };

  if (category === ADULT) {
    fields[ADULT_STUDENT_CHECKBOXES].push(-1);
    fields[ADULT_WHEELCHAIR_CHECKBOXES].push(-1);
  }

  if (category !== ADULT) {
    const pax_wheelchair_ages = `${category}_wheelchair_ages` as const;
    const pax_students_ages = `${category}_student_ages` as const;

    /**
     * We are pre-filling the attributes ages array with -1,
     * so we can check if the attribute are assigned or not
     * for a specific user, once we have the same number of "ages"
     * as the category ages array. So, -1 is acting like a null/undefined.
     */
    fields[pax_wheelchair_ages] = [...state[pax_wheelchair_ages], -1];
    fields[pax_students_ages] = [...state[pax_students_ages], -1];
  }

  if (category === SENIOR) {
    fields[pax_ages].push(AGE_RANGES[category].min);
  }

  return {
    ...state,
    [category]: state[category] + 1,
    ...fields
  };
};

const setPassengersReducer = (
  state: PassengerComplete,
  { payload }: PayloadAction<Partial<PassengerComplete>>
) => {
  const new_state: Partial<PassengerComplete> = {};
  for (const [_key, value] of Object.entries(state)) {
    const key = _key as keyof PassengerComplete;
    const payload_value = payload[key] === undefined ? value : payload[key];
    new_state[key] = payload_value;
  }
  return new_state as PassengerComplete;
};

export const initial_state: PassengerComplete = {
  adult: 1,
  child: 0,
  senior: 0,
  adult_wheelchair: 0,
  child_wheelchair: 0,
  senior_wheelchair: 0,
  child_ages: [],
  senior_ages: [],
  child_wheelchair_ages: [],
  senior_wheelchair_ages: [],
  adult_student: 0,
  child_student: 0,
  child_student_ages: [],
  senior_student: 0,
  senior_student_ages: [],
  adult_student_checkboxes: [-1],
  adult_wheelchair_checkboxes: [-1]
};

const Passengers = createSlice({
  name: "search_form/passengers",
  initialState: initial_state,
  reducers: {
    incrementPassengersCount: incrementPassengersCountReducer,
    removePassenger: removePassengerReducer,
    assignAttributeToPassenger: assignAttributeToPassengerReducer,
    setPassengerAge: setPassengerAgeReducer,
    unassignAttributeToPassenger: unassignAttributeToPassengerReducer,
    unassignAttributeToAllPassengers: unassignAttributeToAllPassengersReducer,
    setPassengers: setPassengersReducer
  }
});

export const {
  incrementPassengersCount,
  assignAttributeToPassenger,
  setPassengerAge,
  unassignAttributeToPassenger,
  unassignAttributeToAllPassengers,
  setPassengers,
  removePassenger
} = Passengers.actions;

export default Passengers.reducer;
