import React from "react";

import { IconArrowLeftRight, UnstyledButton } from "@busbud/horizon";

import { useSearchStateActions } from "@app/components/search-form-hydrated/autocomplete-input-hydrated/hooks/use-search-state-hooks";
import { getGlobalSearchFormState } from "@app/components/search-form-hydrated/search-form-errors";
import type { SearchFormValues } from "@app/components/search-form-hydrated/search-form-hydrated";
import { useLiteAppContext } from "@app/helpers/hooks";
import { clickedReverseCities } from "@app/tracking/search-tracking";
import { yieldToMain } from "@app/utils/scheduler-yield";

import type { UseFormGetValues, UseFormSetValue } from "react-hook-form";

interface SwapOriginDestinationButtonProps {
  is_disabled: boolean;
  setValue: UseFormSetValue<SearchFormValues>;
  getValues: UseFormGetValues<SearchFormValues>;
}
export const SwapOriginDestinationButton = React.memo(
  ({ is_disabled, setValue, getValues }: SwapOriginDestinationButtonProps) => {
    const { liteTranslator, tracker } = useLiteAppContext();
    const { swapCities } = useSearchStateActions();

    const handleLocationSwap = React.useCallback(async () => {
      const { origin: origin_value, destination: destination_value } =
        getValues();
      setValue("origin", destination_value, {
        shouldDirty: true
      });
      setValue("destination", origin_value, {
        shouldDirty: true
      });
      await yieldToMain();
      swapCities();

      const { origin, destination } = getGlobalSearchFormState();
      tracker?.asyncTrack(clickedReverseCities(origin, destination));
    }, [swapCities, setValue, getValues, tracker]);

    return (
      <UnstyledButton
        role="button" // TODO: Remove role after fix in horizon
        id="swap-cities-icon"
        data-testid="swap-cities-button"
        className="border rotate-45 rounded-md border-width-sm border-color-primary bg-color-canvas-primary p-100 active:bg-color-canvas-secondary sm:border-color-static-transparent sm:p-075 sm:hover:border-color-primary"
        ariaLabel={liteTranslator.t("!landing.input-label.swap-locations")}
        onClick={handleLocationSwap}
        isDisabled={is_disabled}
      >
        <IconArrowLeftRight
          size="md"
          className="rotate-45 text-icon-color-primary sm:-rotate-45"
        />
      </UnstyledButton>
    );
  }
);
